import React from 'react';
import { FeaturedContainer, ItemsContainer } from './Featured.styles';
import FeaturedItem from './FeaturedItem';
import CallToAction from '../../molecules/CallToAction/CallToAction';

export default function FeaturedResourceSection({
  component: {
    header,
    kicker,
    subheader,
    featuredItems,
    callToAction,
    } }) {
  return (
    <FeaturedContainer>
        <div className="inner-wrapper">
            <div className="header-container">
                <h3 className='header'>{header}</h3>
                <span className="subheader">{subheader}</span>
            </div>
            <ItemsContainer>
              {featuredItems.map((item) => (
                <>
                  <FeaturedItem {...item} />
                  <div className='divider' />
                </> 
              ))}
            </ItemsContainer>
            <div className='cta-container'>
              <CallToAction
                value={callToAction.label}
                url={callToAction.url}
                con
                variant="outlineWhite"
              />
            </div>   
        </div>
    </FeaturedContainer>
  )
}
